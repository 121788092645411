.form-group-file{
    border:1px #0054A6 solid;
    border-radius: 3px;
    label{
        cursor: pointer;
        display:flex;
        justify-content: center;
        align-items: center;
        color:#0054A6;
        font-size: calc_fluid(13,16, 768, 1920);
        font-weight: 900;
        height: calc_fluid(40,53,320,1920);
        img{
            margin-right: 16px;
            @media(max-width:768px){
                height:60%;
            }
        }
        svg{
            width:20px;
            height:20px;
            object-fit: contain;
            margin-right:10px;
            
            path{
                fill:#0054A6;
            }
        }
    }
}