.modal-typical {
  width: 544px;
  padding: calc_fluid(25,49,320,1920) calc_fluid(30,60,320,1920);
  position: relative;
  @include _600 {
    width: 100%;
    overflow: scroll;
  }
  &-close {
    position: absolute;
    top: 0;
    cursor: pointer;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    &__svg {
      width: 24px;
      height: 24px;
      fill: $blk;
    }
  }
  &-form {
    display: block;
    margin-top: 24px;
  }
  &__header {
    font-weight: 900;
    font-size: calc_fluid(18,28,320,1920);
    line-height: calc_fluid(22,36,320,1920);
    color: #333333;
    text-align: center;
  }
  &__description {
    margin-top: 14px;
    font-size: calc_fluid(12,16,320,1920);
    line-height: calc_fluid(18,24,320,1920);
    color: #878787;
    text-align: center;
  }
  &__send, &__send2 {
    margin-top: 17px;
    font-weight: 900;
    cursor: pointer;
    font-size: calc_fluid(12,16,320,1920);
    line-height: calc_fluid(18,24,320,1920);
    width: 100%;
    height: calc_fluid(40,53,320,1920);
  }
  &-personal {
    margin-top: 13px;
    &, &__link {
      font-size: calc_fluid(11,15,320,1920);
      line-height: calc_fluid(16,21,320,1920);
      color: #878787;
    }
    &__link {
      text-decoration: underline;
      &:hover{
        color: #0054A6;
      }
    }
  }
  &__error {
    display: none;
    margin-top: 12px;
    font-size: calc_fluid(12,16,320,1920);
    line-height: calc_fluid(18,24,320,1920);
    color: $red;
    &.active {
      display: block;
    }
  }
}