.modal {
  &-response {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    min-height: 466px;
  }
  &-success {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    &__icon {
      width: 80px;
      height: 80px;
      fill: none;
    }
    &__header {
      margin-top: 17px;
      color: $blu;
    }
    &__description {
      margin-top: 15px;
    }
  }
  &-error {
    &__icon {
      width: 76px;
      height: 76px;
      fill: none;
    }
    &__header {
      margin-top: 17px;
      color: $red;
      max-width: 252px;
    }
    &__description {
      margin-top: 15px;
    }
  }
  &-load {
    &__icon {
      width: 199px;
      height: 199px;
      fill: none;
    }
    &__header {
      margin-top: 22.4px;
    }
  }
}