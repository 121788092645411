@import "../general";
@import "../mixins";
@import "../media";
@import "../components/base/buttons";
@import "../components/base/header";
@import "../components/base/footer";
@import "../components/modals/base";
@import "../components/base/scroll-top";
@import "../components/base/scroll";
@import "../components/base/cookie";
@import "../components/address-modal";

.phoneMask {
  display: flex;
  height: calc(100vh - 150px);
  width: 100vw;
  align-items: center;
  justify-content: center;

  &__input {
    height: 50px;
    padding-left: 20px;
    font-size: 18px;
    width: 200px;
    background: #fcfcfc;
    border: 1px solid;
    border-radius: 2px;
  }
}

.--desktop {
  @media(max-width: 1240px) {
    display: none !important;
  }
}

.--mobile {
  @media (min-width: 1241px) {
    display: none !important;
  }
}

.main {
  @include _1170 {
    margin-top: 122px;
  }
}

.privacy {
  padding-bottom: 100px;
}

iframe .badge-widget {
  width: 100%;
  height: 100%;
}