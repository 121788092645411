.btn {
  &-f {
    display: flex;
    justify-content:center;
    align-items:center;
    flex-flow: row wrap;
    border-radius: 3px;
    transition: border .3s, color .3s;
  }
  &-blue {
    background: #fff;
    border: 1px solid $blu;
    color: $blu;
    & > svg {
      fill: $blu;
    }
    &:hover {
      color: $red;
      border: 1px solid $red;
      & > svg {
        fill: $red;
      }
    }
    &.disabled {
      border: 1px solid $disabled;
      color: $disabled;
      pointer-events: none;
      & > svg {
        fill: $disabled;
      }
    }
  }
  &-white {
    color: $blu;
    background: #fff;
    border: 1px solid #fff;
    &:hover {
      background: $blu;
      color: #fff;
    }
    &.disabled {
      color: $disabled1;
      border: 1px solid $disabled1;
      background: $blu;
      pointer-events: none;
    }
  }
  &-red {
    border: 1px solid $red;
    background: $red;
    color: #fff;
    &:hover {
      background: $blu;
      border: 1px solid $blu;
    }
    &:focus {
      background: $b-blu;
      border: 1px solid $b-blu;
    }
    &.disabled {
      color: #fff;
      background:$disabled;
      border: 1px solid $disabled;
      pointer-events: none;
    }
  }
}