.modal-domains {
  padding: 35px 30px;
  width: 300px;
  &-item {
    color: #333;
    font-size: 16px;
    line-height: 24px;
    padding: 5px 0;
    display: block;
    &.active {
      color: $blu;
    }
    &:hover {
      color: $red;
    }
  }
}