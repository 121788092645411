.header {
  &-desktop {
    width: 100%;
    box-shadow: 0 9px 28px rgba(0, 0, 0, 0.1);

    &-wrapper{
      .phone-container{
        display: grid; 
        grid-template-columns: 1fr auto; 
        margin-top: 10px;
        span{
          font-size: 12px;
        }
       div:nth-child(3){
          grid-column: span 2;
        }
      }
    }
    &-top {
      width: 100%;
      padding: 0 75px;
      position: relative;

      &:after {
        position: absolute;
        content: "";
        height: 1px;
        background: #E2E2E2;
        width: calc(100% - 150px);
        left: 75px;
        top: 100%;
      }

      @include _1600 {
        padding: 0 15px;

        &:after {
          width: calc(100% - 30px);
          left: 15px;
        }
      }

      height: 110px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-left,
      &-right {
        display: flex;
        align-items: center;
        height: 100%;
      }

      &-center {
        @media(max-width: 1240px) {
          margin: 15px 0 25px 0;
        }

        &-button {
          padding: 14px 36px;
          border-radius: 3px;
          background-color: #ED1847;
          color: white;
          font-size: 16px;
          font-weight: 900;

          @media(max-width: 1300px) {
            padding: 14px 15px;
            font-size: 12px;
          }

          @media(max-width: 1370px) {
            padding: 14px 20px;
            font-size: 14px;
          }



        }
      }

      &-right {
        position: relative;

        &>* {
          transition: opacity .3s ease-in-out;
          opacity: 1;
        }

        &.active {
          &>* {

            opacity: 0;
          }
        }
      }
    }

    &-logo {
      width: calc_fluid(281, 366, 1170, 1920);
      height: calc_fluid(69.75, 72.67, 1170, 1920);
      display: flex;
      align-items: center;

      &__img {
        height: calc_fluid(69.75, 72.67, 1170, 1920);
        width: calc_fluid(104.625, 109, 1170, 1920);
      }

      &__text {
        font-size: calc_fluid(14.5, 19, 768, 1920);
        line-height: calc_fluid(18.43, 24, 768, 1920);
        color: #333;
        margin-left: calc_fluid(21.5, 28, 768, 1920);
        max-width: 229px;
      }
    }

    &-domains {
      margin-left: auto_fluid(86, 1170, 1920);
      display: flex;
      align-items: center;

      @media(max-width: 1500px) {
        margin-left: 0;
      }

      &__icon {
        width: auto_fluid(24, 1170, 1920);
        height: 24px;
        fill: $red;
      }

      &__city {
        margin-left: auto_fluid(10, 1170, 1920);
        font-size: calc_fluid(14, 18, 1170, 1920);
        line-height: 26px;
        color: $red;
        border-bottom: 1px dashed $red;
      }

      &-near {
        color: #828282;
        margin-left: 18px;
        position: relative;
        font-size: calc_fluid(13, 16, 1170, 1920);
        line-height: 26px;
        border-bottom: 1px dashed #828282;
        font-style: italic;

        &:before {
          position: absolute;
          content: "-";
          font-size: 16px;
          line-height: 24px;
          color: #828282;
          left: -11px;
          top: 2px;
        }
      }
    }

    &-phone {
      &__icon {
        height: auto_fluid(24, 1170, 1920);
        width: 24px;
        fill: $blu;
        margin-right: auto_fluid(10, 1170, 1920);
      }

      display:flex;
      align-items: center;
      color: $blu;
      font-size: calc_fluid(14, 18, 1170, 1920);
      line-height: 26px;
      $hdp: &;

      &:hover {
        #{$hdp}__icon {
          fill: $red;
        }

        color: $red;
      }
    }

    &-callback {
      margin-left: auto_fluid(22, 1170, 1920);
      height: calc_fluid(45, 53, 1170, 1920);
      width: auto_fluid(205, 1170, 1920);
      font-weight: bold;
      font-size: calc_fluid(10, 16, 1170, 1920);
      line-height: 25px;
    }

    &-social {
      height: 44.5px;
      width: 44.5px;
      min-width: 44.5px;
      border: 5px solid $borderED;
      transform: rotate(45deg);
      overflow: hidden;
      margin-left: 44px;
      position: relative;
      z-index: 2;

      &-wrapper {
        top: -23px;
        left: 46px;
        position: absolute;
        transform: rotate(-45deg);
        width: 100%;

        &-inner {
          display: flex;
          // flex-flow: row wrap;
          // justify-content: center;
          // width: 100%;
          // margin-top: 46px;
          margin-right: 32px;
          gap: 5px;

          &.--fix {
            margin: 0;
          }
        }
      }

      &-item {
        margin: 2.5px 0;
        width: 38px;
        height: 38px;
        min-width: 38px;
        min-height: 38px;
        transition: min-width .2s, min-height .2s;

        &__icon {
          width: 100%;
          height: 100%;
          fill: none;
        }
      }

      $hsoc: &;

      &:hover {
        overflow: unset;

        #{$hsoc}-wrapper {
          z-index: 1;
        }
      }
    }

    &-search {
      $hds: &;

      &-activate {
        margin-left: 30px;
        cursor: pointer;

        &-wrapper {
          top: 1px;
          left: 7px;
        }

        &__icon {
          width: 24px;
          height: 24px;
          fill: #333;
        }

        &.active+#{$hds}-wrapper {
          right: 0;
        }
      }

      &-wrapper {
        position: absolute;
        opacity: 1 !important;
        top: 0;
        height: 100%;
        padding-left: 40px;
        right: -40vw;
        display: flex;
        z-index: 13;
        transition: right .3s ease-in-out;
        align-items: center;

        &.hidden {
          display: none;
        }
      }

      position: relative;
      height: 53px;
      width: 431px;
      border: 1px solid $borderCD;
      border-radius: 3px;
      background: #fff;

      &-button {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 70px;
        display: flex;
        justify-content: center;
        align-items: center;

        &__icon {
          width: 24px;
          height: 24px;
          fill: $blu;
        }

        $hsb: &;

        &:focus {
          #{$hsb}__icon {
            fill: $red;
          }
        }
      }

      &__input {
        width: 100%;
        height: 100%;
        border: none;
        appearance: none;
        padding: 0 40px 0 70px;
        font-size: 18px;
        line-height: 24px;
        color: $blk;
      }
    }

    &-bottom {
      padding: 0 75px;

      @include _1600 {
        padding: 0 15px;
      }

      display:flex;
      justify-content: center;

      &-nav {
        display: flex;
        margin-left: 28.75px;
        margin-top: -1px;

        &-item {
          display: flex;
          align-items: center;
          position: relative;
          color: $blk;
          font-size: 16px;
          line-height: 24px;
          font-weight: normal;
          transition: font-weight .2s;

          >a {
            display: flex;
            align-items: center;
            position: relative;
            z-index: 1;
            // padding: 16px 34px;
            padding: 16px 1.6vw;
            color: inherit;

            text-wrap: nowrap;
          }

          &-children {
            top: 100%;
            left: 0;
            z-index: 12;
            background: #fff;
            box-shadow: 0 9px 28px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            position: absolute;
            padding: 24px 47px 20px 44px;
            opacity: 0;
            pointer-events: none;
           &.catalog-item{
            display: grid;
            grid-template-columns: auto auto;
            column-gap: 40px;
            .header-desktop-bottom-nav-item-children-item{
              white-space: nowrap;
            }
           }

            &-item {
              font-size: 16px;
              line-height: 24px;
              color: $blk;
              display: block; 
              padding: 6px 0;
              width: 100%;
              font-weight: normal;
              
              &.last{
                color: #0054A6;
                font-weight: 700;
                &:visited{
                  color:#0054A6;
                }
                &:hover {
                  color: $red;
                }
              }

              &:visited {
                color: $blk;
              }

              &:hover {
                color: $red;
              }
            }
          }

          &__icon {
            margin-left: 12px;
            width: 16px;
            transition: transform .3s;
            height: 16px;
            fill: $blk;
          }

          $navi: &;

          &__hover {
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            opacity: 0;
            transition: opacity .2s;
          }

          &.hover {
            font-weight: bold;
            color: #fff;

            #{$navi}__icon {
              fill: #fff;
            }
          }

          &:hover {
            color: $red;

            #{$navi} {
              &-children {
                opacity: 1;
                pointer-events: all;
              }

              &__icon {
                fill: $red;
                transform: rotateX(180deg);
              }

              &__hover {
                opacity: 1;
              }
            }
          }

          @include _1600 {
            font-size: calc_fluid(12, 16, 1170, 1920);

            // padding: 16px calc_fluid(25, 45, 1170, 1920);
            &__icon {
              width: calc_fluid(12, 16, 1170, 1920);
              margin-left: calc_fluid(9, 12, 1170, 1920);
            }
          }
        }
      }
    }
  }

  position: relative;
  $header: &;

  &-domain_select {
    position: absolute;
    top: 95px;
    width: 100%;
    height: calc(100vh - 95px);
    opacity: 0;
    transition: opacity .3s;
    z-index: -1;
    padding: 50px 75px;
    overflow-y: scroll;

    @include _1170 {
      position: fixed;
      top: 0;
      height: 100vh;
    }

    &-top {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-search {
      position: relative;
      border: 1px solid $borderCD;
      border-radius: 3px;
      height: 53px;
      width: 674px;

      @include _979 {
        width: calc(100% - 60px);
      }

      &-button {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 64px;
        display: flex;
        justify-content: center;
        align-items: center;

        &__icon {
          width: 24px;
          height: 24px;
          fill: $blu;
        }

        $hds: &;

        &:focus {
          #{$hds}__icon {
            fill: $red;
          }
        }
      }

      &__input {
        border: none;
        appearance: none;
        font-size: 16px;
        line-height: 24px;
        color: $blk;
        padding: 0 64px 0 20px;
        height: 100%;
        width: 100%;

        &::placeholder {
          font-size: 16px;
          line-height: 24px;
          color: #878787;
        }
      }
    }

    &-domains {
      margin-top: 36px;
      columns: 7;

      @include _1920 {
        columns: 5;

        @include _1320 {
          columns: 4;

          @include _979 {
            columns: 3;

            @include _768 {
              columns: 2;

              @include _600 {
                columns: 1;
              }
            }
          }
        }
      }

      &-item {
        display: flex;
        break-inside: avoid-column;
        margin-top: -5.5px;

        &-domain {
          padding: 12.5px;
          font-weight: 500;
          font-size: 16px;
          display: block;
          line-height: 24px;
          color: $blk;

          &:hover {
            color: $red;
            text-decoration: underline;
          }

          &.hidden {
            display: none;
          }
        }

        &__letter {
          margin-top: 5.5px;
          min-height: 37px;
          min-width: 37px;
          height: 37px;
          width: 37px;
          font-weight: 900;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          border: 1px solid $red;
          color: $red;
          margin-right: 19px;
        }

        &.hidden {
          display: none;
        }
      }
    }

    &__close {
      cursor: pointer;
      height: 40px;
      width: 40px;
      fill: $blu;

      @include _979 {
        height: 36px;
        width: 36px;
      }
    }
  }

  &.domain-select {
    height: 150px;

    #{$header} {
      &-desktop-top {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 11;
        box-shadow: 0 9px 28px rgba(0, 0, 0, 0.1);
      }

      &-domain_select {
        background: #fff;
        opacity: 1;
        z-index: 10;
      }

      &-desktop-bottom {
        display: none;
      }

      @include _1170 {
        &-domain_select {
          z-index: 12;
          padding: 25px 40px;
        }

        @include _768 {
          padding: 15px 20px;
        }
      }
    }
  }

  &-wrapper {
    width: 100%;
  }

  &-mobile {
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 11;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

    &-top {
      height: calc_fluid(65, 80, 320, 768);
      border-bottom: 1px solid $borderED;
      position: relative;
      background: #fff;

      &-menu {
        height: 100%;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 4px;

        &__icon {
          height: 24px;
          width: 24px;
          color: $blk;

          &:first-child {
            display: flex;
          }

          &:last-child {
            display: none;
          }
        }

        $hmm: &;

        &.active {
          #{$hmm}__icon {
            &:first-child {
              display: none;
            }

            &:last-child {
              display: flex;
            }
          }
        }
      }
    }

    &-bottom {
      height: 56px;
      display: flex;
      align-items: center;
      padding: 8px 15px;
      justify-content: space-between;
      background: #fff;

      .phone-container{
        display: grid;
        grid-template-columns: 1fr auto;
        column-gap: 10px;
       align-items: center;
      }
    }

    &-menu {
      position: fixed;
      left: -200%;
      top: 121px;
      background: #fff;
      height: calc(100vh - 121px);
      width: 492px;

      @include _600 {
        width: 281px;
      }

      z-index: 10;
      transition: left .3s ease-in-out;
      padding: 14px 24px 22px 15px;
      overflow-y: scroll;

      &-top {
        display: flex;

        @include _600 {
          display: block;
        }
      }

      &-bg {
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 100vw;
        background: rgba(0, 0, 0, .37);
        transition: opacity .2s;
        z-index: -2;
        opacity: 0;

        &.active {
          z-index: 9;
          opacity: 1;
        }
      }

      &.active {
        left: 0;
      }

      &-domains {
        display: flex;
        align-items: center;

        &__icon {
          height: 24px;
          width: 24px;

          @include _768 {
            width: calc_fluid(18, 24, 320, 768);
            height: calc_fluid(18, 24, 320, 768);
          }

          margin-right: 10px;
          fill: $red;
        }

        &__text {
          color: $red;
          font-size: 18px;
          line-height: 24px;

          @include _768 {
            font-size: calc_fluid(13, 18, 320, 768);
          }

          border-bottom: 1px dashed $red;
        }

        $hmd: &;

        &:hover,
        &:focus {
          #{$hmd} {

            &__icon,
            &__text {
              fill: $blu;
            }
          }
        }
      }

      &-shops {
        margin-left: 19px;
        display: flex;
        font-size: 16px;
        line-height: 24px;
        color: #828282;
        border-bottom: 1px dashed #828282;
        width: fit-content;
        margin-top: 2px;
        position: relative;

        &:before {
          position: absolute;
          left: -11px;
          top: 0;
          content: "-";
          font-family: "Noto Sans", sans-serif;

          @include _600 {
            display: none;
          }
        }

        @include _768 {
          margin-left: calc_fluid(29, 19, 320, 768);
          font-size: calc_fluid(13, 16, 320, 768);
          line-height: 23px;
        }

        &:hover {
          color: $red;
          border-bottom: 1px dashed $red;
        }
      }

      &-catalog {
        width: 100%;
        margin-top: 25.5px;

        &-children {
          position: fixed;
          z-index: 10;
          left: -200%;
          top: 121px;
          background: #fff;
          height: calc(100vh - 121px);
          width: 281px;
          overflow-y: scroll;
          transition: left .3s ease-in-out;
          padding-bottom: 12px;

          &-back {
            display: flex;
            padding: 0 19px;
            align-items: center;
            height: 52px;
            background: $blu;
            margin-bottom: 8px;

            &__text {
              max-width: calc(100% - 24px);
              font-weight: bold;
              font-size: 13px;
              line-height: 19px;
              color: #fff;
            }

            &__icon {
              margin-right: 8px;
              width: 16px;
              height: 16px;
              fill: #fff;
              transform: rotate(90deg);
            }
          }

          &-item {
            margin: 0 15px;
            padding: 10px 0;
            font-size: 14px;
            line-height: 19px;
            display: block;
            color: $blk;
            &.last{
              color: #0054A6;
              font-weight: 700;
            }
          }
        }

        $hcatalog: &;

        &-item {
          display: flex;
          align-items: center;

          &__text {
            font-size: 16px;
            line-height: 24px;

            @include _768 {
              width: calc_fluid(14, 16, 320, 768);
              line-height: calc_fluid(19, 24, 320, 768);
            }

            min-width: fit-content;
            max-width: calc(100% - 36px);
            color:$blk;
            padding: 15px 0;

            @include _768 {
              padding: calc_fluid(8.5, 15, 320, 768);
            }
          }

          &__subitems_btn {
            cursor: pointer;
            width: 100%;
            padding: 5.5px 0;
          }

          &__icon {
            width: 20px;
            height: 20px;
            margin-top: 4px;
            margin-left: 20px;
            transform: rotate(-90deg);
            fill: $blu;

            @include _768 {
              width: calc_fluid(16, 20, 320, 768);
              height: calc_fluid(16, 20, 320, 768);
              margin-left: calc_fluid(20, 13, 320, 768);
            }
          }

          &.active {
            &+#{$hcatalog}-children {
              left: 0;
            }
          }
        }
      }

      &-search {
        margin-top: 35px;
        width: 100%;
        height: 53px;

        @include _600 {
          margin-top: 14.5px;
          height: 40px;
        }

        position: relative;
        border: 1px solid #D8D8D8;
        border-radius: 3px;

        &-button {
          position: absolute;
          top: 0;
          right: 0;
          width: 64px;

          @include _768 {
            width: calc_fluid(56, 64, 320, 768);
          }

          height:100%;
          display:flex;
          align-items: center;
          justify-content:center;

          &__icon {
            width: 20px;
            height: 20px;

            @include _768 {
              width: calc_fluid(16, 20, 320, 768);
              height: calc_fluid(16, 20, 320, 768);
            }

            fill: $blu;
          }

          $hmsb: &;

          &:hover,
          &:focus {
            #{$hmsb}__icon {
              fill: $red;
            }
          }
        }

        &__input {
          width: 100%;
          height: 100%;
          border: none;
          padding: 0 56px 0 20px;
          font-size: 16px;
          line-height: 24px;

          @include _768 {
            font-size: calc_fluid(14, 16, 320, 768);
            line-height: calc_fluid(22, 24, 320, 768);
          }

          &::placeholder {
            font-size: 16px;
            line-height: 24px;

            @include _768 {
              font-size: calc_fluid(14, 16, 320, 768);
              line-height: calc_fluid(22, 24, 320, 768);
            }

            color: #8B8B8B;
          }

          color: $blk;
        }
      }

      &-social {
        margin-top: 45px;
        display: flex;
        justify-content: space-between;
        padding-right: 59px;

        @include _600 {
          margin-top: 15px;
          padding-right: 12px;
        }

        &-item {
          width: 53.5px;
          height: 53.5px;

          @include _600 {
            height: 29.65px;
            width: 29.65px;
          }


          &__icon {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    &-wrapper {
      position: absolute;
      top: 5px;
      left: calc(50% - 100px);

      display: flex;
      align-items: center;
      justify-self: center;

      gap: 15%;
    }

    &-logo {
      // position: absolute;
      top: 0;
      // left: calc(50% - 100px);
      // width: 190px;
      height: 100%;
      display: flex;
      align-items: center;

      &__svg {
        height: calc_fluid(46.9, 69.75, 320, 768);
        width: calc_fluid(68, 104.625, 320, 768);
      }

      &__text {
        margin-left: calc_fluid(12, 21.5, 320, 768);
        font-weight: 500;
        font-size: calc_fluid(11, 14.6, 320, 768);
        color: $blk;
        max-width: calc_fluid(121, 175.8, 320, 768);
        line-height: calc_fluid(14, 18.4, 320, 768);
      }
    }

    &-phone {
      font-size: 12px;
      line-height: 14px;
      color: $blu;
      text-decoration: underline;
      display: flex;
      align-items: center;
      height: 100%;
      width:100%;
      margin-left: 15%;

      @include _768 {
        margin-left: 0;
      }

      &__icon {
        width: 18.13px;
        height: 18.13px;
        margin-right: 8.16px;
        fill: $blu;
      }

      $hmp: &;

      &:hover {
        color: $red;

        #{$hmp}__icon {
          fill: $red;
        }
      }
    }

    &-callback {
      width: calc_fluid(137, 600, 320, 1170);

      @include _768 {
        // width: calc(100% - 153px);
        width: 120px;
        font-size: 10px;
      }

      height: 40px;
      font-weight: 900;
      font-size: 11px;
      line-height: 14px;
    }
  }

  .domain-phone-name{
    color: #0054A6; 
    font-size: 12px;
    margin-left: 32px;
  }
}


.mobile-domain-phone{
  font-size: 11px;
  color: #0054A6;
}