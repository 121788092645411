@import "fonts";
@import "variables";

html {
  overflow-x: hidden;
  &.active {
    overflow: hidden;
  }
}

input {
  font-family: 'Noto Sans', sans-serif;
}

body {
  font-family: 'Noto Sans', sans-serif;
  margin: 0;
  padding:0;
  background: #fff;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  &:focus {
    outline: none;
  }
}

a {
  text-decoration: none;
  transition: color .3s;
  &__icon {
    transition: fill .3s;
  }
  &__text {
    transition: color .3s;
  }
}

b {
  color: $blu;
}

.modal-product {
  margin-top: 30px;
  &__item {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 50px;
    margin-top: 10px;
    text-align: end;
    
    p {
      font-weight: 700;
    }
    span {
      color: #7a7a7a;
    }

  }

  
}