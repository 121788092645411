.address-modal {
  padding: 30px 28px 23px;
  filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.15));
  background: #fff;
  width: 352px;
  position: absolute;
  top: 75px;
  right: 147px;
  display: none;
  &-close {
    position: absolute;
    top: 11px;
    cursor: pointer;
    right: 11px;
    height: 24px;
    width: 24px;
    &__svg {
      height: 100%;
      width: 100%;
      fill: $blk;
    }
  }
  &-bottom {
    margin-top: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__rate {
      height: 50px;
      width: 125px;
    }
    &__call {
      height: 52px;
      width: 140px;
      background: $blu;
      font-weight: 900;
      font-size: 16px;
      cursor: pointer;
      border: 1px solid $blu;
      line-height: 24px;
      &:hover {
        background: #fff;
        color: $blu;
      }
    }
  }
  &__header {
    font-size: 15px;
    line-height: 20px;
    font-style: italic;
    color: #878787;
  }
  &__title {
    margin-top: 2px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: $blk;
  }
  &__phone {
    margin-top: 14px;
    display: block;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: $blu;
  }
  &__time {
    margin-top: 16px;
    font-size: 16px;
    line-height: 24px;
    color: $blk;
    width: 200px;
  }
  &.active {
    display: block;
  }
  @include _979 {
    &-close {
      display: none;
    }
    margin-top: 15px;
    position: unset;
    width: 100%;
    display: block;
  }
}