.scroll-top {
  position: fixed;
  bottom: 75px;
  right: 75px;
  height: 120px;
  width: 120px;
  @include _1600 {
    bottom: 15px;
    right: 15px;
  }
  @include _1170 {
    display: none;
  }
  transition: opacity .3s;
  opacity: 0;
  z-index: -1;
  &-wrapper {
    display: flex;
    transform: rotate(45deg);
    height: 85px;
    width: 85px;

    justify-content: center;
    align-items: center;
    background: $red;
    border: 1px solid $red;
    cursor: pointer;
    transition: border .3s, background-color .3s;
  }
  &__icon {
    width: 53px;
    height: 53px;
    fill: #fff;
    transition: fill .3s;
    transform: rotate(-45deg);
  }
  $st:&;
  &.active {
    opacity: 1;
    z-index: 3;
  }
  &.end {
    position: absolute;
    top: 66px;
    right: 0;
    @include _1800 {
      top: -195px;
    }
    #{$st} {
      &-wrapper {
        background: none;
        border: 1px solid #C5D7EA;
      }
      &__icon {
        fill: #C5D7EA;
      }
    }
  }
}