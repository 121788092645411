.footer {
  width: 100%;
  padding: 0 75px;
  background: #EDF2F8;
  @include _1600 {
    padding: 0 15px;
  }
  &-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 106px;
    border-bottom: 1px solid #C5D7EA;
    &-top {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include _600 {
        display: flex;
        flex-flow: row wrap;
      }
    }
    @include _1170 {
      flex-flow: row wrap;
      height: unset;
      padding: 15px 0 28px;
    }
    &-right {
      display: flex;
      height: 100%;
      align-items: center;
      @include _979 {
        flex-direction: column;
        align-items: flex-end;
      }
      @include _600 {
        padding-top: 26px;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
        border-top: 1px solid #C5D7EA;
        width: 100%;
      }
    }
  }
  &-middle {
    display: flex;
    padding: 38px 0 45px;
    
    @include _1800 {
      flex-flow: row wrap;
      @include _1320 {
        padding: 0 0 45px;
      }
    }
    
    
    
  }
  &-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 54px;
    flex-flow: row wrap;
    border-top: 1px solid #C5D7EA;
    @include _1170 {
      height: unset;
    }
    &-left {
      display: flex;
      align-items: center;
      height: 100%;
      flex-flow: row wrap;
      @include _1170 {
        margin-top: -2px;
        @include _768 {
          margin-top: 0;
          padding-top: 14px;
        }
        width: 100%;
      }
    }
    &__copyright {
      margin-right: 43px;
      padding: 2px 0;
      @include _768 {
        width: 100%;
        margin-right: 0;
      }
    }
    &__copyright, &__privacy {
      font-size: 14px;
      line-height: 20px;
      color: $blk;
    }
    &__privacy {
      padding: 16px 0;
      text-decoration: underline;
      margin-right: 37px;
      &:hover {
        color: $red;
      }
    }
    &-sitemap {
      display: flex;
      color: $blu;
      font-size: 14px;
      align-items: center;
      line-height: 20px;
      &__icon {
        height: 24px;
        width: 24px;
        fill: $blu;
        margin-right: 9px;
      }
      $fbsitemap: &;
      &:hover {
        color: $red;
        #{$fbsitemap}__icon {
          fill: $red;
        }
      }
      @include _768 {
        width: 100%;
      }
    }
    &-placestart {
      padding: 10px 0;
      color: $blk;
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 18px;
      @include _1170 {
        margin-top: 15px;
      }
      $fbps:&;
      &__logo {
        height: 21px;
        width: 21px;
        fill: #9BBF2E;
        margin-left: 5px;
        margin-top: 2px;
      }
      &__text {
        height: 10px;
        width: 79.05px;
        fill: $blk;
      }
      &:hover {
        color: $red;
        #{$fbps} {
          &__logo, &__text {
            fill: $red;
          }
        }
      }
    }
  }
  &-nav {
    display: flex;
    flex-flow: row wrap;
    justify-content: normal;

    @include _1800 {
      width: 100%;
    }

    // width: calc(100% - 412px);
    // justify-content: space-between;
    // @include _768 {
    //   padding-right: calc_fluid(0,177, 600, 768);
    // }

    &-item {
      width: calc(100% / 4);
      margin-bottom: 30px;


      &.edit-width {
        max-width: 300px;
      }

      &.autoglass.edit {
        width: calc(100% / 2);
        @include _860 {
          width: calc(100%);

          .edit {
            max-height: 230px;
          }

          @include _600 {
            .edit {
              max-height: 290px;
            }
            @include _480 {
              width: 100%;
              .edit {
                max-height: initial;
              }
            }
          }
        }
      }
      
      @include _1320 {
        margin-top: 30px;
        width: calc(100% / 4);
        
        @include _860 {
          width: calc(100% / 3);
  
          @include _600 {
            width: calc(100% / 2);
            @include _480 {
              width: 100%;
            }
          }
        }
      }
      &__title {
        display: block;
        font-weight: bold;
        font-size: calc_fluid(18, 22, 320, 1920);
        line-height: calc_fluid(20, 26, 320, 1920);
        color: $blk;
        &:hover {
          color: $red;
        }
      }
      &-subitem {
        display: block;
        padding: calc_fluid(6, 8.5, 320,1920) 0;
        color: $blk;
        font-size: calc_fluid(14,15,320,1920);
        line-height: 20px;
        &.edit {
          padding-right: calc_fluid(6, 8.5, 320,1920);
        }
        &:hover {
          color: $red;
        }
        &s {
          max-width: 210px;
          margin-top: calc_fluid(8, 11.5, 320, 1920);
          &.edit {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            // gap: 5px;
            max-height: 350px;
            max-width: 100%;
          }
        }
      }
    }
  }
  &-info {
    width: 412px;
    position: relative;

    .footer-nav-item {
      width: 100%;
    }
    &__link {
      font-weight: bold;
      font-size: calc_fluid(18, 22, 320, 1920);
      line-height: calc_fluid(20, 26, 320, 1920);
      color: $blk;
      margin-top: 20px;
      margin-right: 20px;
      &:first-child {
        margin-top: 0;
      }
      &:hover {
        color: $red;
      }
    }
    &-drive2 {
      margin-top: 34px;
      margin-right: 34px;
      height: 46px;
      width: 112px;
      &__img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &-drive2, &__link {
      display: block;
    }
    &-social {
      margin-top: 34.86px;
      margin-right: 34.86px;
      display: flex;
      width: 283.14px;
      @include _480 {
        margin-left: 10.48px;
        width: 250.23px;
      }
      justify-content: space-between;
      &-item {
        height: calc_fluid(37.81, 43.83, 320, 1920);
        width: calc_fluid(37.81, 43.83, 320, 1920);
        &__icon {
          height: 100%;
          width: 100%;
          fill: none;
          object-fit: contain;
        }
      }
    }
    &-payment {
      margin-top: 36.31px;
      @include _768 {
        margin-top: 25.71px;
      }
      &__title {
        font-size: 15px;
        line-height: 26px;
        color: $blk;
      }
      &-items {
        margin-top: 18px;
        display: flex;
      }
      &-item {
        height: 11px;
        object-fit: contain;
        margin-right: 20px;
      }
    }
    @include _1800 {
      &-left {
        width: 100%;
        display: flex;
        justify-content: space-around;
        @include _768 {
          display: block;
        }
      }
      &-right {
        width: 100%;
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        justify-content: space-around;
        margin-top: 20px;
        @include _768 {
          margin-top: 40px;
          display: block;
        }
        &-left {
          display: flex;
          align-items: baseline;
          @include _600 {
            display: block;
          }
        }
      }
      margin-top: 30px;
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-around;
      &__link, &-drive2, &-social, &-payment {
        margin-top: 0;
      }
      @include _768 {
        &-payment {
          margin-top: 40px;
        }
        @include _600 {
          &-social {
            margin-top: 30px;
          }
          &-payment {
            margin-top: 25.5px;
          }
        }
        &__link {
          margin-top: 14px;
        }
      }
    }
  }
  &-logo {
    width: calc_fluid(322, 366, 768, 1920);
    height: calc_fluid(70.5, 72.67, 768, 1920);
    @include _768 {
      width: calc_fluid(229,322,320,768);
      height: calc_fluid(46.2,70.5, 320, 768);
    }
    display: flex;
    align-items: center;
    &__icon {
      height: calc_fluid(70.48,72.67, 768, 1920);
      width: calc_fluid(102, 109, 768, 1920);
      @include _768 {
        height: calc_fluid(46.2,70.5, 320,768);
        width: calc_fluid(67, 102,320,768);
      }
    }
    &__text {
      font-size: calc_fluid(18, 19, 768, 1920);
      line-height: calc_fluid(23, 24, 768, 1920);
      color: #333;
      margin-left: calc_fluid(20,28,768,1920);
      max-width: calc_fluid(200,229,768,1920);
      @include _768 {
        font-size: calc_fluid(13,18,320,768);
        line-height: calc_fluid(17.3,23,320,768);
        max-width: calc_fluid(141,200,320,768);
      }
    }
    @include _600 {
      margin: 15px 0 0 15px;
    }
    @include _1170 {
      margin-bottom: 14.5px;
    }
  }
  &-phone {
    $fp: &;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: calc_fluid(18, 22, 320, 1920);
    line-height: calc_fluid(20, 26, 320, 1920);
    color: $blu;
    padding: 30px 0;
    margin-right: 23px;
    &__icon {
      height: calc_fluid(22,26,320,1920);
      width: calc_fluid(22,26,320,1920);
      fill: $blu;
      margin-right: calc_fluid(10,9,320,1920);
    }
    @include _979 {
      margin-right: 0;
      padding: 30px 0 0;
      @include _600 {
        padding: 0;
      }
    }
    &:hover {
      color: $red;
      #{$fp}__icon {
        fill: $red;
      }
    }
  }
  &-callback {
    $fc: &;
    padding: 30px 0;
    margin-right: 41px;
    &__text {
      font-style: italic;
      font-size: 16px;
      line-height: 24px;
      color: $blu;
      border-bottom: 1px dashed $blu;
    }
    &:hover {
      #{$fc}__text {
        color: $red;
        border-bottom: 1px dashed $red;
      }
    }
    @include _979 {
      margin-right: 0;
      padding: 0 0 30px;
      @include _600 {
        margin-top: 3px;
        padding: 0;
      }
    }
  }
  &-registration {
    height: calc_fluid(40,53,320,1920);
    width: calc_fluid(176,243,320,1920);
    font-weight: 900;
    font-size: calc_fluid(11,16,320,1920);
    line-height: calc_fluid(15,24,320,1920);
    @include _1170 {
      width: 50%;
      @include _979 {
        width: 100%;
        @include _600 {
          width: 176px;
        }
      }
    }
    @include _600 {
      margin: 15px 0 0 0;
    }
    &.--meduim {
      display: none;
      @include _1170 {
        display: flex;
      }
      @include _600 {
        display: none;
      }
    }
    &.--dm {
      display: flex;
      @include _1170 {
        display: none;
        @include _600 {
          display: flex;
        }
      }
    }
  }
  @include _600 {
    padding: 0;
    &-bottom {
      padding: 0 15px;
    }
    &-top {
      padding: 0;
      &-right {
        padding: 26px 15px 28px;
      }
    }
    &-middle {
      padding: 0 15px 36.77px;
    }
  }
}